import React, { useState, useRef, useEffect } from 'react';

import { ContactTypes } from '../../../types';

import ContactFormPardot from '../../molecules/ContactFormPardot';
import InlineLink from '../../atoms/InlineLink';
import LinkRulertelInline from '../../atoms/LinkRulertelInline';
import Button from '../../atoms/Button';
import RichText from '../../atoms/RichText';
import Styled from './index.styled';

const ContactPardot = ({
	dataGALabel,
	textBlock,
	form,
	hidePhoneNumber,
	contactSuccess,
	gdprCopy,
	gdprRichText,
	compRef,
	isTabbedComponent,
	anchorId,
}: ContactTypes) => {
	const contactRef = useRef<null | HTMLDivElement>(null);
	const [formSubmitted, setFormsubmitted] = useState(false);

	const GetGdprCopy = () => {
		if (gdprCopy === null && gdprRichText === null) {
			return 'By selecting Enquire today, you acknowledge that you will be contacted by a member of Bruntwood and also agree to the terms of our';
		}

		if (gdprRichText !== null && gdprRichText !== undefined)
			return <RichText content={gdprRichText} gdprLink />;
		return gdprCopy;
	};

	const Form = () => (
		<>
			<Styled.FormTitle as="h3">{form.title}</Styled.FormTitle>
			<ContactFormPardot setFormsubmitted={setFormsubmitted} formData={form} />
			<Styled.GDPR as="div">
				{GetGdprCopy()}{' '}
				{gdprRichText === null && (
					<InlineLink title="GDPR Policy" url="/privacy-policy/" />
				)}
			</Styled.GDPR>
		</>
	);

	const FormSuccess = () => (
		<>
			<Styled.FormTitle as="h3">{contactSuccess.title}</Styled.FormTitle>
			<Styled.Body>{contactSuccess.copy}</Styled.Body>
			<Button
				buttonText="Back to form"
				buttonCategory="component - form enquiry"
				buttonAction="Back to form"
				buttonLabel={dataGALabel}
				as="button"
				buttonType="tertiary"
				callback={() => setFormsubmitted(false)}
				iconName="arrowLeft"
				iconSize="small"
			/>
		</>
	);

	useEffect(() => {
		if (formSubmitted === false) {
			return;
		}

		contactRef?.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	}, [formSubmitted]);

	return (
		<Styled.ContactPardot
			ref={compRef}
			as="section"
			isTabbedComponent={isTabbedComponent}
			id={anchorId || ''}
		>
			<Styled.Content isTabbedComponent={isTabbedComponent}>
				{textBlock && (
					<Styled.Inner>
						<Styled.Title as="h2">{textBlock.title}</Styled.Title>
						<Styled.Body>{textBlock.copyList[0]}</Styled.Body>
						<Styled.BodyRegular>{textBlock.copyList[1]}</Styled.BodyRegular>

						{!hidePhoneNumber && <LinkRulertelInline />}
					</Styled.Inner>
				)}

				<Styled.Form ref={contactRef} data-enquire-form>
					{formSubmitted ? <FormSuccess /> : <Form />}
				</Styled.Form>
			</Styled.Content>
		</Styled.ContactPardot>
	);
};

export default ContactPardot;
